<template lang="pug">
  user-form(v-if="!$apollo.queries.user.loading", v-model="user", :newUser="false", @submit="updateUser", @cancel="cancel")
</template>

<script>
import UserForm from "@/components/forms/UserForm.vue";
import UpdateUserMutation from "@/graphql/mutations/companies/company_users/UpdateUserMutation.gql";
import UserQuery from "@/graphql/queries/User.gql";
export default {
  apollo: {
    user: {
      query: UserQuery,
      variables() {
        return { id: this.$route.params.user_id }
      }
    },
  },
  data() {
    return {
      user: {
        name: null,
        email: null,
        role: null,
        dateFormat: null,
        timezone: null,
        company: {
          id: this.$route.params.id,
        },
      },
    };
  },
  methods: {
    updateUser() {
      return this.$apollo
        .mutate({
          mutation: UpdateUserMutation,
          variables: {
            input: {
              id: this.$route.params.user_id,
              name: this.user.name,
              email: this.user.email,
              role: this.user.role,
              dateFormat: this.user.dateFormat,
              timezone: this.user.timezone,
            },
          },
        })
        .then(() => {
          this.$router.push(`/companies/${this.$route.params.id}/users`),
            this.$flash.success("The user was successfully edited");
        });
    },
    cancel() {
      this.$router.push(`/companies/${this.$route.params.id}/users`);
    },
  },
  components: {
    UserForm,
  },
};
</script>
